*,
*::before,
*::after {
  box-sizing: border-box;
}

html,
body {
  margin: 0;
  padding: 0;
}

#__next {
  display: flex;
  flex-direction: column;
  min-height: 100vh;
}

input[type="number"]::-webkit-inner-spin-button {
  display: inline-block;
}

[data-lock-scroll] {
  width: 100vw;
  pointer-events: none;
  position: fixed;
}