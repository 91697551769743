#contentReset {
  font-size: 1rem !important;
  overflow-wrap: normal;
  white-space: normal;
  color: currentColor !important;
}

#contentReset * {
  color: currentColor;
}

#contentReset h1,
#contentReset h2,
#contentReset h3,
#contentReset h4 {
  font-weight: 300 !important;
}

#contentReset h1 {
  font-size: 2em !important;
}

#contentReset h2 {
  font-size: 1.5em !important;
}

#contentReset h3 {
  font-size: 1.2em !important;
}

#contentReset h4 {
  font-size: 1em !important;
}

#contentReset h2,
#contentReset h3 {
  color: #786459 !important;
  font-weight: 700 !important;
}

#contentReset strong,
#contentReset b {
  font-weight: 700;
}

.grecaptcha-badge {
  visibility: hidden;
}

html {
	scroll-behavior: smooth;
}

@media screen and (prefers-reduced-motion: reduce) {
	html {
		scroll-behavior: auto;
	}
}

[data-lock-scroll] {
  width: 100vw;
  pointer-events: none;
  position: fixed;
}